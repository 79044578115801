import React from "react";

const indexDelays = {
  0: "delay-1",
  1: "delay-2",
  2: "delay-3",
  3: "delay-4",
  4: "delay-5",
  5: "delay-6",
  6: "delay-7",
  7: "delay-8",
  8: "delay-9",
  9: "delay-10",
  10: "delay-11",
  11: "delay-12",
  12: "delay-13",
  13: "delay-14",
  14: "delay-15",
};

const StaffCard = ({ index, profile, setSelectedIndex }) => {
  const slug = profile.name.replace(" ", "-").toLowerCase();

  var classes;
  var backgroundImg = {
    backgroundImage: "url(" + profile.imageURL + ")",
  };

  if (index < 15) {
    classes = indexDelays[index] + " profile-card animated fadeInUp";
  } else if ((index - 2) % 3 === 0) {
    classes = "profileCard wow delay-3 fadeInUp";
  } else if ((index - 1) % 3 === 0) {
    classes = "profileCard wow delay-2 fadeInUp";
  } else {
    classes = "profileCard wow fadeInUp";
  }

  return (
    <div
      id={"member-" + slug}
      data-slug={slug}
      onClick={() => setSelectedIndex(index)}
      className='profile-link'
    >
      <div data-slug={slug} key={index} className={classes}>
        <div
          data-slug={slug}
          style={backgroundImg}
          id={"profile-" + slug + "-img"}
          className='profile-card-img'
        ></div>
        <p data-slug={slug} className='profile-card-title'>
          {profile.name}
        </p>
        <p data-slug={slug} className='profile-card-desc'>
          {profile.title}
        </p>
      </div>
    </div>
  );
};

export default StaffCard;
