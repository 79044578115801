import React from 'react';  

const Input = (props) => {
    return (  
        <div className="form-group">
            <input
                className="form-input"
                id={props.name}
                name={props.name}
                type={props.type}
                onChange={props.handleChange}
                placeholder={props.placeholder} 
                value={props.inputValue}
            />
         </div>
    )
}

export default Input;