import React, { useState } from "react";
import { mobileVisualHTML } from "./helpers/htmlTemplates";

/**
 * TO DO: Explore a way of returning the image dimensions to the frontend before the images are loaded
 * https://www.andyshora.com/css-image-container-padding-hack.html
 */

const LazyLoadImage = ({ projTitle, imageSrc, index }) => {
  const [isLoaded, setLoadingStatus] = useState(false);

  if (isLoaded || imageSrc?.includes("mp4")) {
    return mobileVisualHTML(index, imageSrc, projTitle);
  }

  return (
    <div key={index} className='image-placeholder-container'>
      <div className='image-placeholder-loading'>
        <img
          loading='lazy'
          style={{ opacity: 0, width: "100vw" }}
          src={imageSrc}
          alt={projTitle}
          onLoad={() => setLoadingStatus(true)}
        />
      </div>
    </div>
  );
};

export default LazyLoadImage;
