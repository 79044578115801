import get from "lodash/get";
import { getAssetURLMapping } from "./assets";

const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const buildPublicationCards = (data) => {
  const assetURLsByID = getAssetURLMapping(data);
  const allPublicationsRawData = get(data, "data.items", []);

  const publications = allPublicationsRawData.reduce((set, publication) => {
    const imageURL =
      assetURLsByID[get(publication, "fields.displayImage.sys.id")];

    const carouselImages = get(publication, "fields.carouselImages", []).map(
      (slides) => assetURLsByID[get(slides, "sys.id")]
    );

    const pdfFile = assetURLsByID[get(publication, "fields.pdfFile.sys.id")];

    const publicationCard = {
      title: get(publication, "fields.publicationTitle"),
      description: get(publication, "fields.publicationDescription"),
      date: get(publication, "fields.publicationDate"),
      url: get(publication, "fields.publicationUrl"),
      imageURL,
      carouselImages,
      pdfFile,
      slug: get(
        publication,
        "fields.publicationTitle",
        get(publication, "fields.publicationDate")
      )
        .replace("-", " ")
        .replace(/\s+/g, "-")
        .toLowerCase(),
    };
    set.push(publicationCard);
    return set;
  }, []);

  const sortedByDate = publications.sort((a, b) => {
    const [monthA, yearA] = a.date?.split(" ");
    const [monthB, yearB] = b.date?.split(" ");

    if (yearA !== yearB) return yearB - yearA;
    return MONTHS.indexOf(monthB) - MONTHS.indexOf(monthA);
  });

  return sortedByDate;
};
