import React, { useEffect, useState, useCallback } from "react";
import Loader from "react-loader-spinner";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import axios from "axios";
import { withRouter } from "react-router-dom";

import { SLIDE_PAGE_TRANSITION_TIME_MS } from "../../constants";
import { applyFadeOutRedirect } from "../../helpers";
import { buildPublicationCards } from "../../helpers/contentful/publicationsPage";
import styles from "./styles";

const RIGHT_ARROW_CURSOR = "mouse-next";
const LEFT_ARROW_CURSOR = "mouse-prev";
const EXIT_CURSOR = "mouse-end";

const PublicationView = ({
  publicationsStore,
  setPublicationsStore,
  history,
}) => {
  const [slides, setSlides] = useState([]);
  const [currentSlideIndex, setCurrentSlide] = useState(0);
  const [carousel, setCarousel] = useState();

  const container = document.getElementById("press-slider-container");
  const lastIndex = slides.length - 1;
  const isOnFirstSlide = currentSlideIndex === 0;
  const isLastSlide = currentSlideIndex === lastIndex;

  const setCursorArrows = useCallback(
    (e) => {
      if (!carousel) {
        return;
      }
      carousel.classList.remove(LEFT_ARROW_CURSOR);
      carousel.classList.remove(RIGHT_ARROW_CURSOR);
      carousel.classList.remove(EXIT_CURSOR);

      const isLastSlide = currentSlideIndex === slides.length - 1;

      //Should display Right Arrow
      if (e.clientX > container.offsetWidth / 2) {
        carousel.classList.add(isLastSlide ? EXIT_CURSOR : RIGHT_ARROW_CURSOR);
        //Should display Left Arrow
      } else {
        carousel.classList.add(LEFT_ARROW_CURSOR);
      }
      return;
    },
    [carousel, container, currentSlideIndex, slides]
  );

  const routeChange = useCallback(
    () => applyFadeOutRedirect(container, history, "/press", true),
    [container, history]
  );

  const handleSlideClick = useCallback(
    (e) => {
      e.preventDefault();
      setCursorArrows(e);

      const isOnRightSide = e.clientX > container.offsetWidth / 2;

      if (isOnRightSide) {
        return isLastSlide
          ? routeChange()
          : setCurrentSlide(currentSlideIndex + 1);
      }

      if (isOnFirstSlide) {
        return;
      }

      setCurrentSlide(currentSlideIndex - 1);
    },
    [
      container,
      currentSlideIndex,
      isLastSlide,
      isOnFirstSlide,
      routeChange,
      setCursorArrows,
    ]
  );

  useEffect(() => {
    const carouselElement = document.getElementsByClassName("carousel")[0];

    if (slides.length > 0 && carouselElement && !carousel) {
      window.scrollTo(0, 0);
      setCarousel(carouselElement);
    }
  }, [setCarousel, carousel, slides]);

  useEffect(() => {
    const path = window.location.pathname;
    const publicationSlug = path?.substring(path?.lastIndexOf("/") + 1);

    const getPublications = async () => {
      const publicationDataSet = await axios.get(
        `https://cdn.contentful.com/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}/environments/master/entries?access_token=${process.env.REACT_APP_CONTENTFUL_API}&content_type=publication`
      );

      const publications = await buildPublicationCards(publicationDataSet);

      const { carouselImages, title } = publications.find(
        ({ slug }) => slug === publicationSlug
      );
      document.title = `Paul Sangha Creative | ${title}`;

      setSlides(carouselImages);
      return setPublicationsStore(publications);
    };

    if (slides.length > 0) {
      return;
    }

    if (publicationsStore.length > 0) {
      const { carouselImages, title } = publicationsStore.find(
        ({ slug }) => slug === publicationSlug
      );
      document.title = `Paul Sangha Creative | ${title}`;
      return setSlides(carouselImages);
    }

    getPublications();
  }, [publicationsStore, setPublicationsStore, slides]);

  if (slides.length === 0) {
    return (
      <div className='loader-container'>
        <Loader type='Oval' color='#000000' height={40} width={40} />
      </div>
    );
  }

  return (
    <div
      id='press-slider-container'
      style={styles.carouselContainer}
      onMouseMove={setCursorArrows}
    >
      <div onClick={handleSlideClick}>
        <Carousel
          selectedItem={currentSlideIndex}
          useKeyboardArrows={true}
          showIndicators={false}
          showStatus={false}
          showArrows={false}
          showThumbs={false}
          transitionTime={SLIDE_PAGE_TRANSITION_TIME_MS}
        >
          {slides.map((slide, i) => (
            <img style={styles.pressImgSlide} alt={slide} key={i} src={slide} />
          ))}
        </Carousel>
      </div>
      <div style={styles.backButton} onClick={routeChange}>
        BACK
      </div>
    </div>
  );
};

export default withRouter(PublicationView);
