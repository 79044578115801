import React from "react";
import Panel from "./Panel";

const Accordion = ({ jobPostings = [] }) => {
  if (jobPostings.length === 0) {
    return null;
  }

  return (
    <div className='accordion'>
      {jobPostings.map((posting, i) => (
        <Panel key={i} posting={posting} />
      ))}
    </div>
  );
};

export default Accordion;
