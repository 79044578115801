import "./styles/App.scss";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Menu from "./components/header";
import Body from "./components/body";
import SplashPage from "./components/splashpage";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      headerColor: "#000000",
      menuColor: "#000000",
      menuButtonColor: "#000000",
      headerTransparent: false,
      projectCategory: "",
      projectSlug: "",
      gridAnimationTop: 0,
      gridAnimationLeft: 0,
      selectedProject: {},
      shuffled: false,
      publications: [],
    };
    this.setMenuButtonColor = this.setMenuButtonColor.bind(this);
    this.setHeaderProperties = this.setHeaderProperties.bind(this);
    this.setMenuProperties = this.setMenuProperties.bind(this);
    this.setProjectCategory = this.setProjectCategory.bind(this);
    this.setProjectSlug = this.setProjectSlug.bind(this);
    this.setGridAnimationPos = this.setGridAnimationPos.bind(this);
    this.setSelectedProject = this.setSelectedProject.bind(this);
    this.setShuffled = this.setShuffled.bind(this);
    this.setPublicationsStore = this.setPublicationsStore.bind(this);
  }
  setHeaderProperties(color, isTransparent) {
    this.setState({
      headerColor: color,
      headerTransparent: isTransparent,
    });
  }
  setShuffled(isShuffled) {
    this.setState({
      shuffled: isShuffled,
    });
  }
  setMenuButtonColor(color) {
    this.setState({
      menuButtonColor: color,
    });
  }
  setMenuProperties(color) {
    this.setState({
      menuColor: color,
    });
  }
  setProjectCategory(category) {
    this.setState({
      projectCategory: category,
    });
  }
  setProjectSlug(slug) {
    this.setState({
      projectSlug: slug,
    });
  }
  setSelectedProject(project) {
    this.setState({
      selectedProject: project,
    });
  }
  setGridAnimationPos(top, left) {
    this.setState({
      gridAnimationTop: top,
      gridAnimationLeft: left,
    });
  }

  setPublicationsStore(publications) {
    this.setState({
      publications,
    });
  }

  componentDidMount() {
    document.title = "Paul Sangha Creative | Landscape Architects";
  }
  render() {
    return (
      <Router>
        <div className='main'>
          <Menu
            headerColor={this.state.headerColor}
            menuButtonColor={this.state.menuButtonColor}
            menuColor={this.state.menuColor}
            isTransparent={this.state.headerTransparent}
            setProjectCategory={this.setProjectCategory}
          />
          <Body
            //Project Page and Project View related
            projectCategory={this.state.projectCategory}
            projectSlug={this.state.projectSlug}
            setProjectSlug={this.setProjectSlug}
            selectedProject={this.state.selectedProject}
            setSelectedProject={this.setSelectedProject}
            //Press
            setPublicationsStore={this.setPublicationsStore}
            publicationsStore={this.state.publications} //Help save another call to contentful
            //UI States
            setHeaderProperties={this.setHeaderProperties}
            setMenuButtonColor={this.setMenuButtonColor}
            setMenuProperties={this.setMenuProperties}
            /**
             * TODO: Check if these are still required
             */

            // Project View Component
            // Don't believe this is used anymore as projects now fade in instead of zooming in from corner of project cards
            setGridAnimationPos={this.setGridAnimationPos}
            gridAnimationTop={this.state.gridAnimationTop}
            gridAnimationLeft={this.state.gridAnimationLeft}
            // Homepage slideshow
            // Helper function created to shuffle images on homepage
            setShuffled={this.setShuffled}
            isShuffled={this.state.shuffled}
          />
          <SplashPage />
        </div>
      </Router>
    );
  }
}

export default App;
