import React, { useState, useRef, useEffect, useCallback } from "react";
import { buildJobDescriptionHTML } from "../../helpers";

const Panel = ({ posting }) => {
  const { jobTitle, jobDescription } = posting;
  const [isPanelActive, setPanelActive] = useState(false);
  const [panelClassName, setPanelClassName] = useState("panel");
  const content = useRef(null);

  const toggleAccordion = useCallback(() => setPanelActive(!isPanelActive), [
    isPanelActive,
  ]);

  const showPanelClass = {
    transition: "max-height 1s ease-out",
    maxHeight: `${content?.current?.scrollHeight}px`,
    overflow: "auto",
  };

  const noPanelClass = {
    transition: "max-height 0.3s ease-in",
    maxHeight: 0,
    overflow: "hidden",
  };

  useEffect(() => {
    const panelClassName = isPanelActive ? "panel active" : "panel";
    setPanelClassName(panelClassName);
  }, [isPanelActive]);

  if (!jobTitle || !jobDescription) {
    return null;
  }

  return (
    <>
      <div className={panelClassName} onClick={toggleAccordion}>
        <div className='panel-title'>{jobTitle}</div>
      </div>
      <div
        ref={content}
        style={isPanelActive ? showPanelClass : noPanelClass}
        dangerouslySetInnerHTML={{
          __html: buildJobDescriptionHTML(jobDescription),
        }}
      ></div>
    </>
  );
};

export default Panel;
