import React, { useState, useEffect } from "react";
import axios from "axios";
import WOW from "wow.js";
import Loader from "react-loader-spinner";

import { buildPublicationCards } from "../../helpers";
import PublicationCard from "./PublicationCard";

const Publications = ({
  setHeaderProperties,
  setMenuButtonColor,
  setMenuProperties,
  setPublicationsStore,
  publicationsStore,
}) => {
  document.title = "Paul Sangha Creative | Press";

  const [publications, setPublications] = useState(publicationsStore);
  useEffect(() => {
    setHeaderProperties("#000000", false);
    setMenuButtonColor("#000000");
    setMenuProperties("#000000");

    const getPublications = async () => {
      const publicationDataSet = await axios.get(
        `https://cdn.contentful.com/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}/environments/master/entries?access_token=${process.env.REACT_APP_CONTENTFUL_API}&content_type=publication`
      );
      const publications = await buildPublicationCards(publicationDataSet);
      setPublications(publications);
      setPublicationsStore(publications);
    };

    //The wow factor
    var wow = new WOW();
    wow.init();

    if (publications.length === 0) {
      getPublications();
    }
  }, [
    publications,
    setHeaderProperties,
    setMenuButtonColor,
    setMenuProperties,
    setPublicationsStore,
  ]);

  if (publications.length === 0) {
    return (
      <div className='loader-container'>
        <Loader type='Oval' color='#000000' height={40} width={40} />
      </div>
    );
  }

  return (
    <div className='profile-page' id='publication-page'>
      <div className='employee-content'>
        <div className='grid-container'>
          {publications.map((publication, index) => (
            <PublicationCard publication={publication} key={index} />
          ))}
        </div>
      </div>
      <span className='footer-cp'>
        Copyright &copy; 2021. All rights reserved.
      </span>
    </div>
  );
};

export default Publications;
