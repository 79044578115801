import React from "react";
import styles from "../styles";
import { visualHTML, paragraphHTML } from "./htmlTemplates";

const getProjectOrder = (projectOrderArray, slideLength) => {
  var projectOrder = [];

  try {
    projectOrder = projectOrderArray.split(";");
  } catch (err) {
    for (var i = 0; i < slideLength; i++) {
      projectOrder.push("F");
    }
  }
  return projectOrder;
};

const createCarouselHTML = (project) => {
  const links = project.image_slides;

  if (
    !project ||
    !links ||
    (Object.keys(project).length === 0 && project.constructor === Object)
  ) {
    return;
  }

  const defaultSrc = project.image_slides[0];
  const projectOrder = getProjectOrder(project.project_order, links.length);

  if (projectOrder.length !== links.length) {
    console.log(
      "Project Order is not same length as project slide images",
      projectOrder.length,
      links.length
    );
  }

  const slides = [];

  for (let j = 0; j < links.length; j++) {
    let key = j;
    const currentImgSrc = links[key];
    const nextImgSrc = links[key + 1];

    let leftSide, rightSide;

    //if it is the first slide, then output the intro slide
    if (key === 0) {
      slides.push(
        <div
          style={styles.parentStyles}
          className='slide-img-container'
          data-key={key}
          key={"data-slide-" + key}
        >
          {visualHTML(key, defaultSrc, styles.fullScreenImg, "slide-img")}
        </div>
      );
    }

    //if it is the second slide, then output the description slide
    else if (key === 1) {
      const largeTextBody = project.project_description.length > 1200;
      leftSide = (
        <div
          style={
            largeTextBody
              ? styles.leftSideEvenStylesLargeText
              : styles.leftSideOddStyles
          }
          className='carousel-left-desc'
        >
          {paragraphHTML(
            key,
            styles.paragraphStyles,
            project.project_description
          )}
        </div>
      );
      rightSide = (
        <div style={styles.rightSideOddStyles} className='carousel-right-desc'>
          {visualHTML(key, currentImgSrc, styles.leftImgstyles)}
        </div>
      );
      slides.push(
        <div
          style={styles.parentStyles}
          className='slide-img-container'
          data-key={key}
          key={"data-slide-" + key}
        >
          {leftSide}
          {rightSide}
        </div>
      );
    }

    //if the last slide, then output the credits slide
    else if (key === links.length - 1) {
      leftSide = (
        <div style={styles.leftSideOddStyles} className='carousel-left-credit'>
          {paragraphHTML(key, styles.paragraphStyles, project.project_credits)}
        </div>
      );
      rightSide = (
        <div
          style={styles.rightSideOddStyles}
          className='carousel-right-credit'
        >
          {visualHTML(key, currentImgSrc, styles.leftImgstyles)}
        </div>
      );
      slides.push(
        <div
          style={styles.parentStyles}
          className='slide-img-container'
          data-key={key}
          key={"data-slide-" + key}
        >
          {leftSide}
          {rightSide}
        </div>
      );
    }

    //if order indicates S, then get the next image also, and the slide should contain S/M
    else if (projectOrder[key] === "L") {
      leftSide = (
        <div style={styles.leftSideEvenStyles} className='carousel-left-even'>
          {visualHTML(key, currentImgSrc, styles.rightImgstyles)}
        </div>
      );
      rightSide = (
        <div style={styles.rightSideEvenStyles} className='carousel-right-even'>
          {visualHTML(key, nextImgSrc, styles.wideMarginImgStyles)}
        </div>
      );
      slides.push(
        <div
          style={styles.parentStyles}
          className='slide-img-container'
          data-key={key}
          key={"data-slide-" + key}
        >
          {leftSide}
          {rightSide}
        </div>
      );
      j++;
    }

    //if order indicates M, then get the next image also, and the slide should contain M/S
    else if (projectOrder[key] === "S") {
      leftSide = (
        <div style={styles.leftSideOddStyles} className='carousel-left-odd'>
          {visualHTML(key, currentImgSrc, styles.wideMarginImgStyles)}
        </div>
      );
      rightSide = (
        <div style={styles.rightSideOddStyles} className='carousel-right-odd'>
          {visualHTML(key, nextImgSrc, styles.leftImgstyles)}
        </div>
      );
      slides.push(
        <div
          style={styles.parentStyles}
          className='slide-img-container'
          data-key={key}
          key={"data-slide-" + key}
        >
          {leftSide}
          {rightSide}
        </div>
      );
      j++;
    }

    //if order indicates L, then output a full image in the slide
    else if (projectOrder[key] === "F") {
      slides.push(
        <div
          style={styles.parentStyles}
          className='slide-img-container'
          data-key={key}
          key={"data-slide-" + key}
        >
          {visualHTML(key, currentImgSrc, styles.fullScreenImg, "slide-img")}
        </div>
      );
    }

    //Otherwise, default to large
    else {
      slides.push(
        <div
          style={styles.parentStyles}
          className='slide-img-container'
          data-key={key}
          key={"data-slide-" + key}
        >
          {visualHTML(key, currentImgSrc, styles.fullScreenImg, "slide-img")}
        </div>
      );
    }
  }
  return slides;
};

export default createCarouselHTML;
