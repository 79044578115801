import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";

import { buildMenuLinks } from "../helpers";

class MenuLinks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      links: [],
    };
    this._handleLinkClick = this._handleLinkClick.bind(this);
  }
  componentDidMount() {
    //Error fix: Prevent carousel from scrolling back to beginning
    var menucloser = document.getElementById("closemenu");
    menucloser.addEventListener("click", function (event) {
      event.preventDefault();
    });

    axios
      .get(
        `https://cdn.contentful.com/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}/environments/master/entries/367e1uw0JM2bWAmz93nMs9?access_token=${process.env.REACT_APP_CONTENTFUL_API}`
      )
      .then(async (response) => {
        const links = await buildMenuLinks(response);
        /**
        * Contentful does not have child links implemented
        
        var navItems = response.data.items;
        var currentLinks = this.state.links;

        for (var key in navItems) {
          var childLinks = [];
          for (var child_key in navItems[key].child_items) {
            childLinks.push({
              id: navItems[key].child_items[child_key].ID,
              text: navItems[key].child_items[child_key].title,
              textLower: navItems[key].child_items[
                child_key
              ].title.toLowerCase(),
              link: navItems[key].child_items[child_key].url,
              isChild: true,
            });
          }
          currentLinks.push({
            id: navItems[key].ID,
            text: navItems[key].title,
            textLower: navItems[key].title.toLowerCase(),
            link: navItems[key].url,
            children: childLinks,
            isChild: false,
          });
        }
        */
        this.setState({
          links,
        });
      });
  }

  // childrenLinks(link, headerStyles) {
  //   var children = link.children;
  //   if (children.length === 0) {
  //     return;
  //   } else {
  //     let childList = [];
  //     let childrenLinks = [];
  //     //Create children
  //     for (let j = 0; j < children.length; j++) {
  //       childrenLinks.push(
  //         <li key={children[j].id} ref={j + 1}>
  //           <Link
  //             data-key={children[j].id}
  //             style={headerStyles}
  //             onClick={this._handleLinkClick}
  //             onMouseEnter={this._handleLinkEnter}
  //             onMouseLeave={this._handleLinkLeave}
  //             to={"/" + link.textLower + "/" + children[j].textLower}
  //           >
  //             {children[j].text}
  //           </Link>
  //         </li>
  //       );
  //     }
  //     //Create the parent and add the children
  //     childList.push(
  //       <ul className='menu-level-2' key={link.id}>
  //         {childrenLinks}
  //       </ul>
  //     );
  //     return childList;
  //   }
  // }

  _handleLinkClick(e) {
    var indicator = document.getElementById("menu-indicator");
    indicator.style.width = "0px";
    var that = this;
    e.persist();
    setTimeout(function () {
      that.props.handleLinkClick(e, that.state.links);
    }, 500);
  }

  _handleLinkEnter(e) {
    var indicator = document.getElementById("menu-indicator");

    var top = e.target.getBoundingClientRect().top;
    var bottom = e.target.getBoundingClientRect().bottom;
    var midpoint = (bottom - top) / 2;

    var menuLeft = document.getElementById("menu").getBoundingClientRect().left;
    var left = e.target.getBoundingClientRect().left;
    var width = left - menuLeft - 20;

    indicator.style.top =
      e.target.getBoundingClientRect().top + midpoint + "px";
    indicator.style.width = width + "px";
  }

  _handleLinkLeave(e) {
    var indicator = document.getElementById("menu-indicator");
    indicator.style.width = "0px";
  }
  render() {
    //var toggleClass = this.props.isTransparent ? "close-menu-white" : "close-menu-black";
    var toggleClass = "close-menu-black";
    var indicatorStyles = {
      background: this.props.menuColor,
    };

    var menuStyles = {
      borderLeft: this.props.isTransparent ? "none" : "none",
      backgroundColor: this.props.isTransparent
        ? "rgba(255, 255, 255, 0.8)"
        : "rgba(255, 255, 255, 0.9)",
    };

    var linkColor = {
      color: this.props.menuColor,
    };

    let links = this.state.links.map((link, i) =>
      link.text === "Instagram" ? (
        <li key={link.id} ref={i + 1}>
          <a
            rel='noopener noreferrer'
            target='_blank'
            data-key={link.id}
            className='menu-link-level-1'
            style={linkColor}
            onClick={this._handleLinkClick}
            onMouseEnter={this._handleLinkEnter}
            onMouseLeave={this._handleLinkLeave}
            href={link.link}
          >
            {link.text}
          </a>
          {/* {this.childrenLinks(link, linkColor)} */}
        </li>
      ) : (
        <li key={link.id} ref={i + 1}>
          <Link
            data-key={link.id}
            className='menu-link-level-1'
            style={linkColor}
            onClick={this._handleLinkClick}
            onMouseEnter={this._handleLinkEnter}
            onMouseLeave={this._handleLinkLeave}
            to={"/" + link.textLower}
          >
            {link.text}
          </Link>
          {/* {this.childrenLinks(link, linkColor)} */}
        </li>
      )
    );

    return (
      <div
        className={this.props.menuStatus + " " + this.props.boxShadowClass}
        id='menu'
        style={menuStyles}
      >
        <span style={indicatorStyles} id='menu-indicator'></span>
        <button className={toggleClass} href='#' id='closemenu'>
          &nbsp;
        </button>
        <ul className='menu-level-1'>{links}</ul>
      </div>
    );
  }
}

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isNotOpen: true,
      boxShadowClass: "",
    };
    this._menuToggle = this._menuToggle.bind(this);
    this._handleDocumentClick = this._handleDocumentClick.bind(this);
    this.handleLinkClick = this.handleLinkClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener("click", this._handleDocumentClick, false);
    document
      .getElementById("closemenu")
      .addEventListener("click", this._menuToggle, false);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this._handleDocumentClick, false);
  }

  handleLinkClick(e, links) {
    this.forceUpdate();
    var key = e.target.getAttribute("data-key");
    var category = "page-not-found";
    var allLinks = [];
    var boxShadowClassName = "";
    for (var i = 0; i < links.length; i++) {
      category = links[i].textLower;
      allLinks[links[i].id] = category;
      // if (!links[i].isChild && links[i].children.length > 0) {
      //   for (var j = 0; j < links[i].children.length; j++) {
      //     category = links[i].children[j].textLower;
      //     allLinks[links[i].children[j].id] = category;
      //   }
      // }
    }
    category = allLinks[key];
    this.props.setProjectCategory(category);

    var menutitle = document.getElementById("menutitle");
    var menubar = document.getElementsByClassName("menubar")[0];
    if (!this.state.isNotOpen) {
      menutitle.style.opacity = 1;
      menubar.style.zIndex = 2;
      menubar.style.height = "auto";
    } else {
      menutitle.style.opacity = 0;
      boxShadowClassName = "menu-box-shadow";
      menubar.style.height = "100vh";
      menubar.style.zIndex = 100;
    }
    this.setState({
      isNotOpen: !this.state.isNotOpen,
      boxShadowClass: boxShadowClassName,
    });
  }

  _handleDocumentClick(e) {
    var menubar = document.getElementsByClassName("menubar")[0];
    if (!this.refs.root.contains(e.target) && this.state.isNotOpen === false) {
      this._menuToggle(e);
      menubar.style.zIndex = 2;
      menubar.style.height = "auto";
    }
  }

  _menuToggle(e) {
    e.stopPropagation();
    var menutitle = document.getElementById("menutitle");
    var menubar = document.getElementsByClassName("menubar")[0];
    var boxShadowClassName = "";
    if (!this.state.isNotOpen) {
      menutitle.style.opacity = 1;
      menubar.style.zIndex = 2;
      menubar.style.height = "auto";
    } else {
      menutitle.style.opacity = 0;
      boxShadowClassName = "menu-box-shadow";
      menubar.style.zIndex = 100;
      // menubar.style.height = "100vh"; //this causes publication page to jump down, temp removing to verify no bugs was introduced
    }
    this.setState({
      isNotOpen: !this.state.isNotOpen,
      boxShadowClass: boxShadowClassName,
    });
  }

  render() {
    let menuStatus = this.state.isNotOpen ? "isNotOpen" : "";
    var headerStyles = {
      color: this.props.headerColor,
    };

    const menuIconStyles = {
      width: isMobile ? 20 : 25,
      height: 1.5,
      backgroundColor: this.props.menuButtonColor,
      margin: isMobile ? "5px 0px" : "7px 0px",
    };

    return (
      <div className='menubar'>
        <div className='menubar-sitetitle'>
          <div className='sitetitle-container'>
            <Link id='logo' className='site-link' to='/' style={headerStyles}>
              <h1 id='site-title'>
                <span className='bold-text'>PAUL SANGHA</span> CREATIVE
              </h1>
              {/* <h5 id='site-tagline'>LANDSCAPE ARCHITECTS</h5> */}
            </Link>
          </div>
        </div>
        <div ref='root'>
          <div className='menuclicker' onClick={this._menuToggle}></div>
          <button id='menutitle' className={menuStatus}>
            <div style={menuIconStyles}></div>
            <div style={menuIconStyles}></div>
            <div style={menuIconStyles}></div>
          </button>
          <MenuLinks
            menuStatus={menuStatus}
            menuColor={this.props.menuColor}
            isTransparent={this.props.isTransparent}
            handleLinkClick={this.handleLinkClick}
            boxShadowClass={this.state.boxShadowClass}
          />
        </div>
      </div>
    );
  }
}

export default Menu;
