import React, { useState, useEffect } from "react";
import axios from "axios";

import Accordion from "./Accordion";
import {
  formatJobPostings,
  formatPageParagraph,
  getFeatureImageLink,
} from "../helpers";
import { PAGE_NAMES } from "../constants";

const noOpeningStatement = (
  <div>
    We’re always looking for talented, driven people who want to change the
    world with us. Though we currently do not have any openings, if you're
    interested in joining our team, we'd like to hear from you. Please send a
    resumé and portfolio for consideration to
    <a href='mailto:info@paulsangha.com'> info@paulsangha.com</a>.
  </div>
);

const Careers = ({
  setHeaderProperties,
  setMenuButtonColor,
  setMenuProperties,
}) => {
  const [jobPostings, setJobPosting] = useState([]);
  const [headerImage, setHeaderImage] = useState();
  const [content, setContent] = useState("");

  useEffect(() => {
    const getJobPostings = async () => {
      const data = await axios.get(
        `https://cdn.contentful.com/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}/environments/master/entries?access_token=${process.env.REACT_APP_CONTENTFUL_API}&content_type=jobPosting`
      );

      const postings = formatJobPostings(data);
      setJobPosting(postings);
    };

    const getPageParagraph = async () => {
      const { data } = await axios.get(
        `https://cdn.contentful.com/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}/environments/master/entries/45YfYF87LSKYXvrsrTHXAo?access_token=${process.env.REACT_APP_CONTENTFUL_API}`
      );
      const html = formatPageParagraph(data, PAGE_NAMES.CAREER);
      setContent(html);
    };

    document.title = "Paul Sangha Creative | Careers";

    setHeaderProperties("#000000", false);
    setMenuButtonColor("#000000");
    setMenuProperties("#000000");
    getJobPostings();
    getPageParagraph();
  }, [setHeaderProperties, setMenuButtonColor, setMenuProperties]);

  useEffect(() => {
    const getHeaderImage = async () => {
      const data = await axios.get(
        `https://cdn.contentful.com/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}/environments/master/entries?access_token=${process.env.REACT_APP_CONTENTFUL_API}&content_type=careerFeatureImage`
      );
      const headerImageLink = getFeatureImageLink(data);
      if (headerImageLink) {
        setHeaderImage(headerImageLink);
      }
    };
    getHeaderImage();
  }, []);

  return (
    <div className='careers-page-parent'>
      <div className='careers-page'>
        <div className='careers-container'>
          {headerImage && (
            <img
              alt='Paul Sangha Creative Office'
              className='careers-feature-img'
              src={headerImage}
            />
          )}
          <br />
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
          <br />
          <h1 id='default-title'>Current Openings</h1>
          {jobPostings.length > 0 ? (
            <Accordion jobPostings={jobPostings} />
          ) : (
            noOpeningStatement
          )}
        </div>
      </div>
      <div className='careers-page-footer'>
        <span className='footer-cp'>
          Copyright &copy; 2021. All rights reserved.
        </span>
      </div>
    </div>
  );
};

export default Careers;
