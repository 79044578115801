import get from "lodash/get";
import { buildDescription } from "./teamList";
import { getAssetURLMapping } from "./assets";

export const formatProjectsList = (data) => {
  const allProjectRawData = get(data, "data.items", []);
  const assetURLsByID = getAssetURLMapping(data);

  const allProjects = allProjectRawData.reduce((projects, project) => {
    const image_slides = get(project, "fields.projectSlides", []).map(
      (slides) => assetURLsByID[get(slides, "sys.id")]
    );

    //if no slides to display, do not display the project
    if (image_slides?.filter((image) => !!image).length === 0) {
      console.info(
        `${get(
          project,
          "fields.projectTitle"
        )} does not have any published images`
      );
      return projects;
    }

    const projectData = {
      project_type: get(project, "fields.projectCategory"),
      image_slides,
      project_order: get(project, "fields.slideOrder"),
      project_description: buildDescription(
        get(project, "fields.projectDescription.content")
      ),
      project_credits: buildDescription(
        get(project, "fields.projectCredits.content")
      ),
      slug: get(project, "fields.projectTitle", "")
        .replaceAll(" ", "-")
        .trim()
        .toLowerCase(),
      title: get(project, "fields.projectTitle"),
      featured_image_src_landscape:
        assetURLsByID[get(project, "fields.featureImageLandscape.sys.id")],
      featured_image_src_portrait:
        assetURLsByID[get(project, "fields.featureImagePortrait.sys.id")],
      priority: get(project, "fields.priority"),
    };

    projects.push(projectData);
    return projects;
  }, []);

  return allProjects;
};

export const sortProjectsByPriority = (categorizedProjects) =>
  Object.keys(categorizedProjects).reduce((agg, category) => {
    const projects = categorizedProjects[category];
    const projectWithPriority = projects.filter(
      (project) => !!project.priority
    );

    const projectWithoutPriority = projects.filter(
      (project) => !project.priority
    );

    const sortedByPriority = projectWithPriority.sort(
      (a, b) => a.priority - b.priority
    );

    agg[category] = [...sortedByPriority, ...projectWithoutPriority];
    return agg;
  }, {});
