import React, { useEffect } from "react";
import Button from "../button";
import LazyLoadImage from "./lazyLoadImage";

const ProjectViewMobile = ({
  projTitle,
  projDescription,
  projCredits,
  projSlides,
  routerBackFunction,
}) => {
  //Return to top of page each time new project is viewed
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const mobileStickyButtonStyles = {
    position: "fixed",
    bottom: "0px",
    zIndex: 1,
    left: 0,
    width: window.innerWidth <= 480 ? "100%" : "25vw", //Display small button on tablet view
  };

  const firstImage = projSlides.shift();

  return (
    <>
      <div className='mobile-project-container'>
        <div>
          <h1 className='mobile-project-title'>{projTitle}</h1>
          <hr className='project-title-line' />
          <br />
        </div>
        <div className='mobile-project-content-container'>
          <LazyLoadImage projTitle={projTitle} imageSrc={firstImage} />
          <p
            className='mobile-project-desc'
            dangerouslySetInnerHTML={{
              __html: projDescription,
            }}
          ></p>
          <Button
            title='View More Projects'
            action={routerBackFunction}
            style={mobileStickyButtonStyles}
          />
          {projSlides.map((image, i) => (
            <LazyLoadImage
              key={i}
              projTitle={projTitle}
              imageSrc={image}
              index={i}
            />
          ))}
          <p
            className='mobile-project-desc'
            dangerouslySetInnerHTML={{
              __html: projCredits,
            }}
          ></p>
        </div>
      </div>
    </>
  );
};

export default ProjectViewMobile;
