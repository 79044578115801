import React, { useEffect, useState } from "react";
import axios from "axios";
import { getSlideUrls, shuffleArrayItems } from "../../helpers";
import { buildSlides, defaultSlides } from "./htmlTemplates";

const HomePageSlideshow = () => {
  const [slides, setSlides] = useState([]);

  useEffect(() => {
    const getSlideShow = async () => {
      try {
        const dataWithUrl = await axios.get(
          `https://cdn.contentful.com/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}/environments/master/entries?access_token=${process.env.REACT_APP_CONTENTFUL_API}&links_to_asset=1cAoNd5FPuouUTzFNdoMJ4`
        );
        const slides = getSlideUrls(dataWithUrl);

        if (slides.length === 0) {
          setSlides(defaultSlides);
        } else {
          setSlides(shuffleArrayItems(slides));
        }
      } catch (e) {
        setSlides(defaultSlides);
        console.error(e);
      }
    };
    if (slides.length === 0) {
      getSlideShow();
    }
  }, [slides]);

  if (slides.length === 0) {
    return null;
  }

  const images = slides.map((image, i) => buildSlides(image, i));

  //Uses kenburn styling
  return <div className='slideshow'>{images}</div>;
};

export default HomePageSlideshow;
