import React from 'react';  

const TextArea = (props) => {
    return (  
        <div className="form-group">
            <textarea
                className="form-textarea"
                id={props.name}
                name={props.name}
                placeholder={props.placeholder} 
                rows={props.rows}
                cols={props.cols}
                onChange={props.handleChange}
                value={props.textAreaValue}
            />
         </div>
    )
}

export default TextArea;