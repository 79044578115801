const carouselContainer = { height: "85vh", top: "100px" };

const pressImgSlide = {
  width: "100%",
  maxHeight: "80vh", //leaves 5vh for back button
  objectFit: "contain",
};

const backButton = {
  color: "black",
  float: "right",
  display: "flex",
  marginRight: "5vw",
  fontWeight: 400,
  fontSize: "20px",
  cursor: "pointer",
  fontFamily: "Oswald",
};

export default {
  carouselContainer,
  backButton,
  pressImgSlide,
};
