import React from "react";

const videoHTML = (key, src, styles) => (
  <video
    autoPlay
    loop
    muted
    playsInline
    key={"data-slide-" + key}
    style={{ ...styles, objectFit: "cover" }}
    alt={"Project Video " + key}
  >
    <source src={src} type='video/mp4' />
  </video>
);

export const visualHTML = (key, src, styles, className) => {
  if (src.includes("mp4") || src.includes(".mov")) {
    return videoHTML(key, src, styles);
  }

  return (
    <img
      className={className}
      key={"data-slide-" + key}
      style={styles}
      alt={"Project Image " + key}
      src={src}
    />
  );
};

export const paragraphHTML = (key, styles, text) => (
  <p
    key={"data-slide-p-" + key}
    style={styles}
    className='padded-multiline'
    dangerouslySetInnerHTML={{
      __html: text,
    }}
  ></p>
);

export const mobileVisualHTML = (index, imageSrc, projTitle) => {
  if (imageSrc.includes("mp4")) {
    return videoHTML(index, imageSrc);
  }

  return (
    <img
      key={index}
      className='wow fadeInUp'
      style={{ padding: 0, width: "100vw" }}
      src={imageSrc}
      alt={projTitle}
    />
  );
};
