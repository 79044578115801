import React, {Component} from 'react';  
import Input from './input';  
import TextArea from './textArea';  
import Button from './button';
import axios from 'axios';

class Form extends Component {  
  constructor(props) {
    super(props);

    this.state = {
      phone: '',
      email: '',
      message: ''
    }
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleClearForm = this.handleClearForm.bind(this);
  }

  encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
  }

  handleFormSubmit(e) {
    // Form submission logic

    axios({
      method: 'POST',
      url: "/",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: this.encode({"form-name":"psc-contact", ...this.state})
    })
    .then(() => {
      this.setState({
        response: "Thank you for your inquiry. We will be in touch shortly."
      });
    })
    .catch(() => {
      this.setState({
        response: "Something went wrong while trying to submit your inquiry. Please try again or do not hesitate to call us."
      });
    });

    e.preventDefault();
    this.handleClearForm();
  }
  handleClearForm() {
    // Logic for resetting the form
    this.setState({
      email: '',
      phone: '',
      message: '',
    });
  }

  render() {
    return (
      <div className="emailForm">
        <form className="form-wrapper" name="psc-contact" onSubmit={this.handleFormSubmit}>
        <input type="hidden" name="form-name" value="psc-contact" />
          <Input 
              type={'email'}
              title= {'Email'} 
              name= {'email'}
              placeholder = {'Email'}
              handleChange={e => this.setState({ email: e.target.value })}
              inputValue={this.state.email}
          />
          <Input 
              type={'phone'}
              title= {'Phone Number'} 
              name= {'phone'}
              placeholder = {'Phone Number'}
              handleChange={e => this.setState({ phone: e.target.value })}
              inputValue={this.state.phone}
          />
          <TextArea 
              type={'text'}
              title= {'Message'} 
              name= {'message'}
              placeholder = {'Name / Project Highlights'}
              handleChange={e => this.setState({ message: e.target.value })}
              textAreaValue={this.state.message}
          /> 
          <Button title={"Submit"} /> 
        </form>
        <p className="res">
          {this.state.response}
        </p>
      </div>
    );
  }
}

export default Form;
