import React from "react";

const ImageComp = ({ project, imageDirection }) => {
  const {
    featured_image_src_portrait,
    featured_image_src_landscape,
    title,
    slug,
    image_slides,
  } = project;

  let featImageSrc =
    imageDirection === "horizontal"
      ? featured_image_src_landscape
      : featured_image_src_portrait;

  //In case a feature image is not set, use the first image from project carousel
  if (!featImageSrc) {
    featImageSrc = image_slides[0];
  }

  if (featImageSrc?.includes(".mp4") || featImageSrc?.includes(".mov")) {
    return (
      <video
        autoPlay
        loop
        muted
        playsInline
        id={"project-" + slug + "-img"}
        className={`img-${imageDirection}`}
        alt={title}
      >
        <source src={featImageSrc} type='video/mp4' />
      </video>
    );
  }
  return (
    <img
      src={featImageSrc}
      data-slug={slug}
      alt={title}
      id={"project-" + slug + "-img"}
      className={`img-${imageDirection}`}
    />
  );
};

export default ImageComp;
