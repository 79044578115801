import get from "lodash/get";
import axios from "axios";

export const buildMenuLinks = async (data) => {
  const menuLinkRawData = get(data, "data.fields.menuLinks", []);

  let menuLinks = [];
  for (const link of menuLinkRawData) {
    const linkEntry = await getLinkURL(get(link, "sys.id"));
    menuLinks.push(linkEntry);
  }
  return menuLinks;
};

export const getLinkURL = async (id) => {
  const linkEntry = await axios.get(
    `https://cdn.contentful.com/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}/environments/master/entries/${id}?access_token=${process.env.REACT_APP_CONTENTFUL_API}`
  );

  return {
    id: get(linkEntry, "data.sys.id"),
    textLower: get(linkEntry, "data.fields.menuDisplayName").toLowerCase(),
    text: get(linkEntry, "data.fields.menuDisplayName"),
    link: get(linkEntry, "data.fields.menuUrl"),
  };
};
