import React from "react";
import Home from "./home";
import ProjectsPage from "./ProjectsPage";
import ProjectView from "./ProjectView";
import TeamList from "./TeamList";
import Contact from "./contact";
import Careers from "./careers";
import Publications from "./Publications";
import PublicationView from "./PublicationView";
import { Route, Switch } from "react-router-dom";

class Body extends React.Component {
  render() {
    const path = window.location.pathname;
    const targetPath = path?.substring(path?.lastIndexOf("/") + 1);
    return (
      <div className='body-container'>
        <Switch>
          <Route exact path='/' render={() => <Home {...this.props} />} />
          <Route
            path={"/project/" + targetPath}
            render={() => <ProjectView {...this.props} />}
          />
          <Route
            exact
            path={"/projects/"}
            render={() => <ProjectsPage {...this.props} />}
          />
          <Route
            path={"/projects/" + targetPath}
            render={() => <ProjectsPage {...this.props} />}
          />
          <Route
            exact
            path={"/projects/archive"}
            render={() => <ProjectsPage {...this.props} />}
          />
          <Route
            exact
            path={"/projects/commercial"}
            render={() => <ProjectsPage {...this.props} />}
          />
          <Route
            exact
            path={"/projects/residential"}
            render={() => <ProjectsPage {...this.props} />}
          />
          <Route path='/profile' render={() => <TeamList {...this.props} />} />
          <Route path='/contact' render={() => <Contact {...this.props} />} />
          <Route path='/careers' render={() => <Careers {...this.props} />} />
          <Route
            path='/press/'
            exact
            render={() => <Publications {...this.props} />}
          />
          <Route
            path={"/press/" + targetPath}
            render={() => <PublicationView {...this.props} />}
          />
        </Switch>
      </div>
    );
  }
}

export default Body;
