import React, { useEffect } from "react";
import HomePageSlideshow from "./HomePageSlideshow";

const Home = ({
  setHeaderProperties,
  setMenuButtonColor,
  setMenuProperties,
}) => {
  useEffect(() => {
    document.title = "Paul Sangha Creative | Landscape Architects";
    setHeaderProperties("#FFFFFF", true);
    setMenuButtonColor("#FFFFFF");
    setMenuProperties("#000000");
  }, [setHeaderProperties, setMenuButtonColor, setMenuProperties]);

  return <HomePageSlideshow />;
};

export default Home;
