import { PAGE_TRANSITION_TIME_MS } from "../constants";

export const applyFadeOutRedirect = (
  container,
  history,
  newPathLink = "/",
  onSmallDevice = false
) => {
  if (!container) {
    return history.push(newPathLink.toLowerCase());
  }
  container.classList.add("page-fade-out");
  /**
   * desktop: need this class so image container doesn't jump
   * on small devices: project images have different layout so does not apply
   **/
  if (!onSmallDevice) {
    container.classList.add("full-screen");
  }

  setTimeout(() => {
    return history.push(newPathLink.toLowerCase());
  }, PAGE_TRANSITION_TIME_MS);
};
