import React from "react";

class SplashPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        var promise = new Promise(function(resolve, reject) {
            var splashpageTitle = document.getElementById("splashpage-title");
            splashpageTitle.classList.add("splashpage-fade-animation");
            // var splashpageTagline = document.getElementById("splashpage-tagline");
            // splashpageTagline.classList.add("splashpage-fade-animation");
            setTimeout(function() {
                resolve(true);
            }, 1900);
        })
        promise.then(bool => {
            var promise = new Promise(function(resolve, reject) {
                var splashpageHr = document.getElementById("splashpage-hr");
                splashpageHr.classList.add("splashpage-line-animation");
                setTimeout(function() {
                    var splashpageTitle = document.getElementById("splashpage-title");
                    // var splashpageTagline = document.getElementById("splashpage-tagline");
                    var splashpageHr = document.getElementById("splashpage-hr");
                    splashpageTitle.classList.add("transparent-animation");
                    // splashpageTagline.classList.add("transparent-animation");
                    splashpageHr.classList.add("transparent-animation");
                    resolve(true);
                }, 1500);
            })
            promise.then(bool => {
                var splashpageTop = document.getElementById("splashpage-top");
                splashpageTop.classList.add("fade-away");
                var splashpageBottom = document.getElementById("splashpage-bottom");
                splashpageBottom.classList.add("fade-away");
                var splashpage = document.getElementById("splashpage");
                setTimeout(function() {
                    splashpageTop.style.display = 'none';
                    splashpageBottom.style.display = 'none';
                    splashpage.style.display = 'none';
                }, 2000)
            });
        });
    }
    render() {
        return (
            <div id="splashpage" className="splashpage">
                <div id="splashpage-top">
                    <h1 id="splashpage-title"><span className="bold-text">PAUL SANGHA</span> CREATIVE</h1>
                    {/* <h1 id="splashpage-tagline">LANDSCAPE ARCHITECTS</h1> */}
                </div>
                <div id="splashpage-hr"></div>
                <div id="splashpage-bottom">
                </div>
            </div>
        ) 
    }
}
  
export default SplashPage