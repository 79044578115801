import get from "lodash/get";

export const formatJobPostings = (items) =>
  get(items, "data.items", []).reduce(
    (
      agg,
      {
        fields: {
          jobTitle = "",
          jobDescription: { content: paragraph = [] },
        },
      }
    ) => {
      const paragraphs = paragraph.reduce((agg, { content: sentence }) => {
        //loop through each sentence and format according to type
        sentence.forEach(
          ({ value, marks = [], nodeType: type, ...bulletPointContent }) => {
            if (type === "text") {
              agg.push({
                text: value,
                style: get(marks, "[0].type", undefined),
              });
              return agg;
            }

            if (type === "list-item") {
              const bulletPoint = get(
                bulletPointContent,
                "content[0].content[0]",
                []
              );

              agg.push({
                text: get(bulletPoint, "value"),
                style: get(bulletPoint, "marks[0].type", undefined),
                type: "list",
              });

              return agg;
            }
          }
        );

        return agg;
      }, []);

      agg.push({
        jobTitle,
        jobDescription: paragraphs,
      });

      return agg;
    },
    []
  );

export const buildJobDescriptionHTML = (paragraph) => {
  const html = paragraph.reduce((agg, { text, type, style }, i) => {
    //no text, then it is a line break
    if (text.length === 1 || text.length === 0) {
      const previousText = paragraph[i - 1];
      //If the previous text was a list, do not include extra break
      agg += previousText.type === "list" ? "<br>" : "</span><br><br>";
      return agg;
    }

    if (type === "list") {
      agg += `<li>${text}</li>`;
      return agg;
    }

    if (style === "bold") {
      agg += `<b>${text}</b>`;
      return agg;
    }

    if (style === "italic") {
      agg += `<i>${text}</i>`;
      return agg;
    }

    agg += text;
    return agg;
  }, "<span>");
  return html;
};

export const getFeatureImageLink = (data) => {
  const featureImage = get(data, "data.includes.Asset[0].fields.file.url", "");
  if (featureImage) {
    return `https:${featureImage}`;
  }
  return;
};
