import get from "lodash/get";
import { getAssetURLMapping } from "./assets";

export const buildStaffProfile = (data) => {
  const assetURLsByID = getAssetURLMapping(data);
  const allStaffRawData = get(data, "data.items", []);

  const staffProfiles = allStaffRawData.reduce((profiles, staff) => {
    const imageURL = assetURLsByID[get(staff, "fields.staffImage.sys.id")];
    const videoURL = assetURLsByID[get(staff, "fields.staffVideo.sys.id")];

    const profile = {
      name: get(staff, "fields.staffName"),
      title: get(staff, "fields.staffTitle"),
      description: buildDescription(
        get(staff, "fields.staffProfileDescription.content")
      ),
      imageURL,
      videoURL,
      credentials: get(staff, "fields.credentials"),
      priority: get(staff, "fields.priority"),
    };

    profiles.push(profile);
    return profiles;
  }, []);

  const profilesWithPriority = staffProfiles.filter(
    (profile) => !!profile.priority
  );

  const profilesWithoutPriority = staffProfiles.filter(
    (profile) => !profile.priority
  );

  const sortedByPriority = profilesWithPriority.sort(
    (a, b) => a.priority - b.priority
  );

  const sortedByFirstName = profilesWithoutPriority.sort((a, b) => {
    return a.name.localeCompare(b.name);
  });

  return [...sortedByPriority, ...sortedByFirstName];
};

export const buildDescription = (descriptions = []) =>
  descriptions.reduce((agg, content) => {
    let { value } = get(content, "content[0]");

    if (value?.trim().length === 0) {
      agg += "</br></br>";
      return agg;
    }

    if (value === "\n") {
      agg += "</br></br>";
      return agg;
    }

    if (value.trim().length === 0) {
      agg += "</br> </br>";
      return agg;
    }

    if (value.includes("\n")) {
      value = value.replace(/\n/g, "</br>");
    }

    agg += `</br>${value}`;
    return agg;
  }, "");
