import React from "react";

export const buildSlides = (src, i) => {
  if (src.includes("mp4")) {
    return videoTemplate(src, i);
  }

  return imageTemplate(src, i);
};

export const imageTemplate = (image, i) => {
  return (
    <div
      key={i}
      className='slideshow-image'
      style={{ backgroundImage: `url(${image})` }}
    ></div>
  );
};

export const videoTemplate = (videoSrc, i) => {
  return (
    <video
      key={i}
      className='slideshow-image-fadeinout'
      autoPlay
      loop
      muted
      playsInline
      style={{
        bottom: 0,
        right: 0,
        minWidth: "100%",
        minHeight: "100%",
        objectFit: "cover",
        overflow: "none",
      }}
    >
      <source src={videoSrc} type='video/mp4' />
    </video>
  );
};

export const defaultSlides = [
  require("../../slideshow/Slideshow-1.jpg"),
  require("../../slideshow/Slideshow-2.jpg"),
  require("../../slideshow/Slideshow-3.jpg"),
  require("../../slideshow/Slideshow-4.jpg"),
  require("../../slideshow/Slideshow-5.jpg"),
  require("../../slideshow/Slideshow-6.jpg"),
  require("../../slideshow/Slideshow-7.jpg"),
];
