import React from "react";
import { isMobile } from "react-device-detect";

const Link = ({ publication, children }) => {
  if (isMobile && !publication.url) {
    return (
      <a
        target='_blank'
        rel='noopener noreferrer'
        href={publication.pdfFile}
        download={publication.slug}
      >
        {children}
      </a>
    );
  }

  return (
    <a rel='noopener noreferrer' target='_blank' href={publication.url}>
      {children}
    </a>
  );
};

export default Link;
