import React from "react";
import { isMobile } from "react-device-detect";
import { withRouter } from "react-router-dom";

import { applyFadeOutRedirect } from "../../helpers";
import Link from "./Link";

const indexDelays = {
  0: "delay-1",
  1: "delay-2",
  2: "delay-3",
  3: "delay-4",
  4: "delay-5",
  5: "delay-6",
  6: "delay-7",
  7: "delay-8",
  8: "delay-9",
  9: "delay-10",
  10: "delay-11",
  11: "delay-12",
  12: "delay-13",
  13: "delay-14",
  14: "delay-15",
};

const PublicationCard = ({ index, publication, history }) => {
  let classes;
  const backgroundImg = {
    backgroundImage: "url(" + publication.imageURL + ")",
  };

  if (index < 15) {
    classes = indexDelays[index] + " profile-card animated fadeInUp";
  } else if ((index - 2) % 3 === 0) {
    classes = "profileCard wow delay-3 fadeInUp";
  } else if ((index - 1) % 3 === 0) {
    classes = "profileCard wow delay-2 fadeInUp";
  } else {
    classes = "profileCard wow fadeInUp";
  }

  const handlePressClick = () => {
    //Open all online publication as new tab or download PDF on mobile
    if (publication.url || isMobile) {
      return;
    }
    //For printed publications, redirect to publicationView to display scanned images on desktop
    if (!publication.carouselImages) {
      return;
    }

    const container = document.getElementById("publication-page");
    return applyFadeOutRedirect(
      container,
      history,
      `/press/${publication.slug}`,
      false
    );
  };

  //If it's not online, and we do not have images to display or pdf to download - do not return anything
  if (
    !publication.url &&
    (!publication.carouselImages || !publication.pdfFile)
  ) {
    return null;
  }

  return (
    <div
      id={"publication-" + index}
      className='publication-card'
      onClick={handlePressClick}
    >
      <Link publication={publication}>
        <div key={index} className={classes}>
          <div
            style={backgroundImg}
            id={"publication-" + index + "-img"}
            className='publication-card-img'
          ></div>
          <p className='publication-card-text publication-description'>
            {publication.description}
          </p>
        </div>
      </Link>
    </div>
  );
};

export default withRouter(PublicationCard);
