import React from "react";
import { withRouter } from "react-router-dom";

import ImageComp from "./ImageComp";
import { applyFadeOutRedirect } from "../../helpers";

const fadeInUpDelayByIndex = {
  0: "delay-1",
  1: "delay-2",
  2: "delay-3",
  3: "delay-4",
  4: "delay-5",
  5: "delay-6",
  6: "delay-7",
  7: "delay-8",
  8: "delay-9",
  9: "delay-10",
  10: "delay-11",
  11: "delay-12",
  12: "delay-13",
  13: "delay-14",
  14: "delay-15",
};

const ProjectCard = ({
  setSelectedProject,
  index,
  column,
  project,
  history,
}) => {
  const handleProjectClick = () => {
    setSelectedProject(project);

    const container = document.getElementById("project-list");

    return applyFadeOutRedirect(
      container,
      history,
      `/project/${project.slug}`,
      false
    );
  };

  const getStyleClass = () => {
    return index < 3
      ? `${fadeInUpDelayByIndex[index]} project-card animated fadeInUp`
      : "projectCard wow fadeInUp";
  };

  const getImageDirection = () => {
    if (column === "left") {
      //Every third image should be horizontal
      return (index + 1) % 3 === 0 ? "horizontal" : "vertical";
    }

    if (column === "right") {
      //Every odd image should be vertical
      return index % 2 !== 0 ? "vertical" : "horizontal";
    }
  };

  return (
    <div className='project-card-container'>
      <div
        id={"project-" + project.slug}
        onClick={handleProjectClick}
        className={`project-link ${column}`}
      >
        <div
          data-slug={project.slug}
          key={project.slug}
          className={`${getStyleClass()} ${getImageDirection()}`}
        >
          <ImageComp project={project} imageDirection={getImageDirection()} />
          <div className='project-card-title-gradient'>
            <p
              data-slug={project.slug}
              className='project-card-title'
              dangerouslySetInnerHTML={{
                __html: project.title,
              }}
            ></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ProjectCard);
