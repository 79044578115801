import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";

const VIDEO_FADE_MS = 600;

const MODAL_STYLES = {
  width: "100vw",
  height: "100vh",
  maxWidth: "100vw",
  maxHeight: "100vh",
  background: "rgba(255,255,255,0.8)",
  position: "fixed",
  top: "0",
  left: "0",
  zIndex: 10,
};

const PROFILE_IMG_STYLES = {
  marginRight: "auto",
  marginLeft: "auto",
  width: "100%",
  height: "100%",
  objectFit: "cover",
  objectPosition: "top",
  position: "absolute",
};

const VIDEO_STYLES = {
  bottom: 0,
  right: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  objectPosition: "top",
  overflow: "none",
};

const Video = ({ src, handleVideoEnd }) => (
  <video
    className='profile-video'
    onEnded={handleVideoEnd}
    autoPlay
    muted
    playsInline
    style={VIDEO_STYLES}
  >
    <source src={src} type='video/mp4' />
  </video>
);

const Modal = ({ selectedProfile, setSelectedIndex }) => {
  const windowWidth = window.screen.width;

  const {
    name,
    title,
    description,
    imageURL,
    videoURL,
    credentials,
  } = selectedProfile;

  useEffect(() => {
    if (!videoURL) {
      const image = document.getElementsByClassName(
        "profile-image-container"
      )[0];
      image.style.backgroundImage = "url(" + imageURL + ")";
      image.style.backgroundSize = "cover";
      image.style.backgroundPosition = "center top";
    }
  }, [videoURL, imageURL]);

  const PROFILE_CONTAINER_STYLES = {
    position: "fixed",
    left: "50%",
    top: "50%",
    transform: "translate(-50%,-50%)",
    maxHeight: windowWidth > 400 ? "90vh" : "95vh",
    overflowY: "scroll",
  };

  const handleVideoEnd = () => {
    const video = document.getElementsByClassName("profile-video")[0];
    const image = document.getElementsByClassName("profile-image-container")[0];

    video.animate({ opacity: 0 }, VIDEO_FADE_MS, "ease-in-out");
    setTimeout(function () {
      image.style.backgroundImage = "url(" + imageURL + ")";
      image.style.backgroundSize = "cover";
      image.style.backgroundPosition = "center top";
    }, 10);

    setTimeout(function () {
      video.style.display = "none";
    }, VIDEO_FADE_MS);
  };

  return (
    <div
      onClick={() => setSelectedIndex(undefined)}
      className='overlay'
      style={MODAL_STYLES}
    >
      <div className='staff-modal' style={PROFILE_CONTAINER_STYLES}>
        <button
          className='close-menu-black'
          href='#'
          id='closemenu'
          //override menu close button position
          style={{ right: "3vw", top: "1vw", zIndex: 10 }}
        ></button>
        <div
          className='profile-image-container'
          style={{ height: isMobile ? "30vh" : "50vh", position: "relative" }}
        >
          {videoURL && (
            <Video
              style={PROFILE_IMG_STYLES}
              src={videoURL}
              handleVideoEnd={handleVideoEnd}
            />
          )}
        </div>

        <div className='modal-footer'>
          <p className='modal-title'>
            <span className='employee-name'>{name}</span> {" - " + title}
            <div>{credentials}</div>
          </p>
          <p
            className='modal-bio'
            dangerouslySetInnerHTML={{ __html: description }}
          ></p>
          {/* <p
            className='modal-excerpt'
            dangerouslySetInnerHTML={{ __html: description }}
          >
            {}
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default Modal;
