import React, { Component } from "react";
import MapContainer from "./mapContainer";
import Address from "./address";
import Form from "./form";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
     
    };
    this.props.setHeaderProperties("#000000", false);
    this.props.setMenuButtonColor("#000000");
    this.props.setMenuProperties("#000000");

  }

  componentDidMount() { 
    document.title = "Paul Sangha Creative | Contact";
  }

  render() {
   
    return (
      <div class="contact-page-parent">
        <div className="contact-page">
          <div className="contact-container">
            <div className="contact-info">
              <Address />
            </div>
            <div className="form-container">
              <h3 className="form-header">What Would You Like To Create?</h3>
              <Form />
            </div>
          </div>
          <div className="contact-container">
            <MapContainer />
          </div>
        </div>
        <div class="contact-page-footer">
          <span class="footer-cp">Copyright &copy; 2021. All rights reserved.</span>
        </div>
      </div>
    );
  }
}

export default Contact;
