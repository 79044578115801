import React, { useState, useEffect } from "react";
import axios from "axios";
import WOW from "wow.js";
import Loader from "react-loader-spinner";

import Modal from "./modal";
import { buildStaffProfile, formatPageParagraph } from "../helpers";
import TeamCard from "./staffCard";
import { PAGE_NAMES } from "../constants";

const TeamList = ({
  setHeaderProperties,
  setMenuButtonColor,
  setMenuProperties,
}) => {
  document.title = "Paul Sangha Creative | Profile";

  const [profiles, setProfiles] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState();
  const [content, setContent] = useState("");

  useEffect(() => {
    setHeaderProperties("#000000", false);
    setMenuButtonColor("#000000");
    setMenuProperties("#000000");

    const getProfiles = async () => {
      const profileDataSet = await axios.get(
        `https://cdn.contentful.com/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}/environments/master/entries?access_token=${process.env.REACT_APP_CONTENTFUL_API}&content_type=staffProfile`
      );

      const profiles = await buildStaffProfile(profileDataSet);
      setProfiles(profiles);
    };

    const getPageParagraph = async () => {
      const { data } = await axios.get(
        `https://cdn.contentful.com/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}/environments/master/entries/19VCUZWqkl5T6MkHX0oxTd?access_token=${process.env.REACT_APP_CONTENTFUL_API}`
      );
      const html = formatPageParagraph(data, PAGE_NAMES.TEAMLIST);
      setContent(html);
    };

    //The wow factor
    var wow = new WOW();
    wow.init();

    if (!content) {
      getProfiles();
      getPageParagraph();
    }
  }, [
    profiles,
    setHeaderProperties,
    setMenuButtonColor,
    setMenuProperties,
    content,
  ]);

  if (!content) {
    return (
      <div className='loader-container'>
        <Loader type='Oval' color='#000000' height={40} width={40} />
      </div>
    );
  }

  return (
    <div className='profile-page'>
      <div
        className='profile-content'
        dangerouslySetInnerHTML={{ __html: content }}
      ></div>
      <div className='employee-content'>
        <div className='grid-container'>
          {profiles.map((profile, index) => (
            <TeamCard
              profile={profile}
              key={index}
              index={index}
              setSelectedIndex={setSelectedIndex}
            />
          ))}
        </div>
      </div>
      {Number.isInteger(selectedIndex) && (
        <Modal
          selectedProfile={profiles[selectedIndex]}
          setSelectedIndex={setSelectedIndex}
        />
      )}
      <span className='footer-cp'>
        Copyright &copy; 2021. All rights reserved.
      </span>
    </div>
  );
};

export default TeamList;
