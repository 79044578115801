var fullScreenImg = {
  height: "100vh",
  width: "100%",
};

var rightImgstyles = {
  height: "100vh",
  minHeight: "100vh",
  width: "100%",
};

var leftImgstyles = {
  height: "100vh",
  minHeight: "100vh",
  width: "100%",
};

var leftSideOddStyles = {
  paddingRight: "8.5%",
  paddingLeft: "8.5%",
  paddingTop: "10.5%",
  paddingBottom: "10.5%",
  width: "40%",
  float: "left",
  height: "100%",
  display: "flex",
};

const leftSideEvenStylesLargeText = {
  ...leftSideOddStyles,
  padding: "8% 5%",
};

var rightSideOddStyles = {
  padding: "0",
  width: "60%",
  float: "left",
};

var leftSideEvenStyles = {
  width: "60%",
  float: "left",
  padding: "0",
};

var rightSideEvenStyles = {
  //paddingTop: "160px",
  //paddingBottom: "140px",
  //paddingRight: "130px",
  //paddingLeft: "130px",
  paddingRight: "8.5%",
  paddingLeft: "8.5%",
  paddingTop: "10.5%",
  paddingBottom: "10.5%",
  width: "40%",
  float: "left",
  height: "100%",
  display: "flex",
};

var paragraphStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  fontSize: "1em",
  marginTop: "10px",
};

var mobileImgStyles = {
  position: "relative",
  top: "50%",
  transform: "translateY(-50%)",
};

// var firstSlideMobileImgStyles = {
//   position: "relative",
//   top: "50%",
//   transform: "translateY(10%)"
// }

// var firstSlideMobileParaStyles = {
//   position: "relative",
//   top: "50%",
//   transform: "translateY(135%)",
//   paddingRight: "25px",
//   paddingLeft: "25px",
//   textAlign: "left"
// }

var mobileParaStyles = {
  position: "relative",
  top: "50%",
  transform: "translateY(-50%)",
  paddingRight: "25px",
  paddingLeft: "25px",
  textAlign: "left",
};

var parentStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  overflow: "hidden",
  height: "100vh",
  width: "100%",
  maxWidth: "100vw",
  opacity: 0,
  transition: "1.5s",
};

const wideMarginImgStyles = {
  height: "100%",
  maxHeight: "600px",
  margin: "auto",
};

export default {
  fullScreenImg,
  wideMarginImgStyles,

  parentStyles,

  paragraphStyles,

  rightImgstyles,
  rightSideOddStyles,
  rightSideEvenStyles,

  leftImgstyles,
  leftSideOddStyles,
  leftSideEvenStylesLargeText,
  leftSideEvenStyles,

  mobileImgStyles,
  mobileParaStyles,
};
